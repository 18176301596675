/* eslint-disable no-undef */
import {
	getMapsHelper
} from './util/mapsHelper';

export const maps = (function () {
	let my = {},
		multiMap = document.getElementById('locations_map'),
		mapData = document.querySelectorAll('.location_item');

	function _startMultiMap() {
		const mapHelper = getMapsHelper();

		mapHelper.ready()
			.then(() => {
				const theMap = mapHelper.createMap({
					element: multiMap,
					locationElementSelector: '.location_item',
					useRichmarker: true,
					markerReducer: el => {
						return {
							lat: el.getAttribute('lat'),
							lng: el.getAttribute('lng'),
							content: `
                        <a class="map_pin" href="${el.getAttribute('link')}" target="${el.getAttribute('target')}">
														<img src="/dist/images/icons/map_pin.svg" alt="" />
                            <div><span>${el.getAttribute('index')}</span></div>
                        </a>
                        `
						};
					}
				});

				google.maps.event.addListenerOnce(theMap, 'idle', () => {
					if (mapData.length < 2) {
						theMap.setZoom(15);
					}
					[].forEach.call(mapData, i => {
						const markerData = mapHelper.getMarker(i);
						markerData.element.addEventListener('mouseenter', () => {
							i.classList.add('hover');
						});
						markerData.element.addEventListener('mouseleave', () => {
							i.classList.remove('hover');
						});
					});
				});
			});
	}

	function _assignListener(){
		/*eslint-disable*/
		let generateMap = false;
		let callback = (entries, observer) => {
			entries.forEach((entry) => {
				if(entry.isIntersecting && !generateMap) {
					_startMultiMap();
					generateMap = true;
				}
			});
		};
			
		let options = {
			root: null,
			threshold: 0.1
		};
			
		let observer = new IntersectionObserver(callback, options);
		
		let target = document.getElementById('locations_map');
		observer.observe(target);
		/*eslint-enable*/
	}

	my.init = function () {
		if (multiMap) {
			_assignListener();
		}
	};

	return my;
})();
