export const faqsToggle = (() => {
	let obj = {};

	obj.init = () => {
		if (document.getElementById('faqs_items_wrap')) {
			setTimeout(function(){
				document.getElementsByClassName('faqs_item_wrap')[0].classList.add('active');
			}, 500);
			const helpItems = document.querySelectorAll('.faqs_item_wrap');
			document.getElementById('faqs_items_wrap').addEventListener('click', function(e) {
				if(e.target && e.target.nodeName === "H3") {
					let el = e.target.parentElement;
					if(el.classList.contains('active')) {
						el.classList.remove('active');
					}
					else {
						helpItems.forEach(i => {
							i.classList.remove('active');
						});
						el.classList.add('active');
					}
				}
			});
		}
	};

	return obj;
})();