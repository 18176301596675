export const homeToggle = (function(){
	let my = {};

	function _mobileToggle() {
		document.getElementById('feats_toggle').addEventListener('click', function(e){
			let btn = e.currentTarget;
			let items = document.getElementById('choose_feats');

			if(btn.classList.contains('open')) {
				btn.classList.remove('open');
				items.classList.remove('open');
			} else {
				btn.classList.add('open');
				items.classList.add('open');
			}
		});
	}

	my.init = function(){
		_mobileToggle();
	};

	return my;
})();

export const homeIframe = (function(){
	let my = {};

	my.init = function(){
		/*eslint-disable*/
		let generateIframe = false;
		let callback = (entries, observer) => {
			entries.forEach((entry) => {
				if(entry.isIntersecting && !generateIframe) {
					generateIframe = true;
					const iframe = document.createElement("iframe");
					// Change this video for Hillsboro when client sends us a new video.
					iframe.src = "https://www.youtube.com/embed/vg3nY6J8j58";
					iframe.width = "100%";
					iframe.height = "100%";
					iframe.title = "YouTube video player";
					iframe.allow = "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture";
					document.querySelector('.home_video').appendChild(iframe);
				}
			});
		};
			
		let options = {
			root: null,
			threshold: 0.1
		};
			
		let observer = new IntersectionObserver(callback, options);
		
		let target = document.querySelector('.home_video');
		observer.observe(target);
		/*eslint-enable*/
	};

	return my;
})();
