import { AutomatitCarousel } from './automatit_carousel.m';
// import { getMapsHelper } from './util/mapsHelper';

export const facilityCaro = (function () {
	let my = {};

	my.init = function () {
		if (document.getElementById('facility_caro')) {

			if (window.slide_images.length > 1) {
				const options = {
					element: document.getElementById('facility_caro'),
					speed: 600,
					images: window.slide_images,
					intervalTiming: 6000,
					imagesAsBackgrounds: true
				};
				let caro = AutomatitCarousel(options);
				document.querySelector('.caro_right').addEventListener('click', () => {
					caro.slideRight();
				});
				document.querySelector('.caro_left').addEventListener('click', () => {
					caro.slideLeft();
				});
			} else {
				// let btns = document.getElementById('facility_caro_chevrons');
				// btns.parentNode.removeChild(btns);
				const options = {
					element: document.getElementById('facility_caro'),
					speed: 600,
					images: window.slide_images,
					imagesAsBackgrounds: true,
					intervalTiming: 0,
					useChevrons: false
				};
				AutomatitCarousel(options);
			}
		}
	};

	return my;
})();

export const facilityHoursToggle = (function () {

	let my = {};

	my.init = function () {
		if(document.querySelector('.facility_access_hrs_toggle')) {
			let btn = document.querySelector('.facility_access_hrs_toggle');
			btn.addEventListener('click', () => {
				document.querySelector('.facility_hours_wrapper').classList.toggle('open');
				btn.classList.toggle('open');
			});
		}
	};

	return my;
})();

export const facilityMapToggle = (function() {

	let my = {};
	my.init = function() {

		if(document.querySelector('#fac_map_toggle')) {

			let mapOverlay = document.querySelector('#fac_map_overlay');

			document.getElementById('fac_map_toggle').addEventListener('click', () => {
				mapOverlay.classList.toggle('open');
			});

			document.getElementById('close_map_overlay').addEventListener('click', () => {
				mapOverlay.classList.toggle('open');
			});
		}
	};

	return my;
})();

export const mobileFacilityInfoToggle = (function () {

	let my = {};

	my.init = function() {

		if(document.querySelector('#mobile_facility_info_btn')) {
			let mobileFacToggle = document.getElementById('mobile_facility_info_btn');
			let mobileFacInfo = document.querySelector('.facility_info_wrapper');

			mobileFacToggle.addEventListener('click', () => {
				mobileFacToggle.classList.toggle('open');
				mobileFacInfo.classList.toggle('open');
			});
		}
	};
	return my;
})();

export const facilityInfoToggle = (function () {

	let my = {};
	my.init = function() {
		if(document.querySelector('.facility_info')) {

			let infoButtons = document.querySelector('.facility_info_buttons');
			let addressWrapper = document.querySelector('.address_wrapper');
			let toggleAddress = document.querySelectorAll('.address_toggles div');

			infoButtons.querySelectorAll(['button']).forEach((button) => {
				button.addEventListener('click', (e) => {

					let count = 0;

					if(button.id === 'fac_map_toggle') {
						return;
					}

					e.target.classList.toggle('open');

					if(button.id === 'fac_about_toggle') {
						toggleAddress[0].classList.toggle('open');
						toggleAddress[1].classList.remove('open');
						addressWrapper.classList.remove('open');

					} else {
						toggleAddress[0].classList.remove('open');
						toggleAddress[1].classList.toggle('open');
						addressWrapper.classList.remove('open');
					}

					toggleAddress.forEach((e) => {
						if(e.classList.contains('open')) {
							count++;
						}
					});

					if(count === 0) {
						addressWrapper.classList.add('open');
					}
				});
			});
		}
	};

	return my;
})();

export const facilitySizeFilter = (function () {

	let my = {};
	my.init = function() {
		if (!Object.entries) {
			Object.entries = function( obj ){
				let ownProps = Object.keys( obj ),
					i = ownProps.length,
					resArray = new Array(i); // preallocate the Array
				while (i--)
				{resArray[i] = [ownProps[i], obj[ownProps[i]]];}

				return resArray;
			};
		}

		let unitRows = document.querySelectorAll('.rates_row');
		let unitHeadings = document.querySelectorAll('.facility_rates_wrapper h4[cat]');
		let unitDividers = document.querySelectorAll('.category_divider');

		let filterFeats = new Set();
		unitRows.forEach(function(i) {
			let cardFeats = i.getAttribute('feats').split('|');
			cardFeats = cardFeats.filter(function(el) {
				return el.length;
			});
			cardFeats.forEach(function(feat) {
				filterFeats.add(feat);
			});
		});
		filterFeats.forEach(function(i) {
			let opt = document.createElement('option');
			opt.value = i;
			opt.text = i;
			document.querySelector('#facility_filter_feats').add(opt);
		});

		document.querySelector('#facility_filters').addEventListener('change', () => {
			let activeSize = document.querySelector('#facility_filter_size').value;
			let activeFeat = document.querySelector('#facility_filter_feats').value;
	
			// console.log(activeSize)
	
			let numSizes = {
				avail: {
					small: 0,
					medium: 0,
					large: 0,
				},
				unavail: {
					small: 0,
					medium: 0,
					large: 0,
				},
			};

			unitRows.forEach(function(row) {
				row.setAttribute('hide', true);
				let matches = {
					size: false,
					feat: false,
				};
	
				if((activeFeat && row.getAttribute('feats').includes(activeFeat)) || !activeFeat) {
					matches.feat = true;
				}
				if((activeSize && activeSize === row.getAttribute('size')) || !activeSize) {
					matches.size = true;
				}
				if(matches.size && matches.feat) {
					row.removeAttribute('hide');
					if(row.getAttribute('avail') === 'true') {
						numSizes.avail[row.getAttribute('size')]++;
					} else {
						numSizes.unavail[row.getAttribute('size')]++;
					}
				}
			});
	
			let headingsToShow = [];
			let headingDividersToShow = [];
	
			Object.keys(numSizes).forEach(function(cat) {
				Object.entries(numSizes[cat]).forEach(function(entry) {
					if(entry[1]) {
						headingsToShow.push(cat+'|'+entry[0]);
						headingDividersToShow.push(cat+'|'+entry[0]);
					}
				});
			});
	
			unitHeadings.forEach(function(heading) {
				heading.style.display = 'none';
	
				if(headingsToShow.includes(heading.getAttribute('cat'))) {
					heading.style.display = 'block';
				}
			});

			unitDividers.forEach(function(divider) {
				divider.style.display = 'none';

				if(headingDividersToShow.includes(divider.getAttribute('cat'))) {
					divider.style.display = 'block';
				}
			});
		});
	};

	return my;
})();

export const facilityAvailUnitsBtn = (function () {

	let my = {};

	my.init = function() {

		if(document.querySelector('#hide_unavail_units')) {

			Element.prototype.switchAttribute = function(attribute, val1, val2) {
				this.setAttribute(attribute, (this.getAttribute(attribute) === val1 ? val2 : val1));
			};

			let unavailUnits = document.querySelector('#facility_unavailable_rates');
			let hideUnvailUnitsBtn = document.querySelector('#hide_unavail_units');
	
			document.querySelector('#hide_unavail_units').addEventListener('click', function() {
				unavailUnits.switchAttribute('state', 'show', 'hide');
				hideUnvailUnitsBtn.switchAttribute('state', 'show', 'hide');
	
				if(unavailUnits.getAttribute('state') === "show") {
					hideUnvailUnitsBtn.querySelector('span').innerHTML = "See Only Available Units";
				} else {
					hideUnvailUnitsBtn.querySelector('span').innerHTML = "See Unavailable Units";
				}
			});
		}
	};

	return my;
})();