export const navToggle = (function(){
	let my = {};

	function _addDoubleClick() {
		[].forEach.call(document.querySelectorAll('.d_click'), (el) => {
			el.addEventListener('click', e => {
				if ('ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) {
					if (!el.hasAttribute('clicked')) {
						e.preventDefault();
						el.setAttribute('clicked', true);
					}
				}
			});
		});
	}

	function _mobileToggle() {
		document.getElementById('header_nav_toggle').addEventListener('click', function(e){
			let btn = e.currentTarget;
			let nav = document.querySelector('.site_header_nav');

			if(btn.classList.contains('open')) {
				btn.classList.remove('open');
				nav.classList.remove('open');
			} else {
				btn.classList.add('open');
				nav.classList.add('open');
			}
		});
	}

	my.init = function(){
		_addDoubleClick();
		_mobileToggle();
	};

	return my;
})();
