import { contactSubmit } from './contact.js';
import { faqsToggle } from './faqs';
import { homeToggle, homeIframe } from './home';
import { reviewsCaro } from './reviews.js';
import { navToggle } from './header.js';
import { maps } from './map.js';
import { initFacilityMap } from './facilityMap.js';
import {
	facilityCaro,
	facilityHoursToggle,
	facilityInfoToggle,
	facilityMapToggle,
	facilitySizeFilter,
	mobileFacilityInfoToggle,
	facilityAvailUnitsBtn,
} from './facility';

faqsToggle.init();
reviewsCaro.init();

facilityCaro.init();
facilityHoursToggle.init();
facilityInfoToggle.init();
facilityMapToggle.init();
mobileFacilityInfoToggle.init();
facilityAvailUnitsBtn.init();

navToggle.init();

maps.init();

if (document.querySelector('#feats_toggle')) {
	homeToggle.init();
}

if (document.querySelector('#map')) {
	initFacilityMap();
}

if (document.querySelector('.rates_row')) {
	facilitySizeFilter.init();
}

if (document.getElementById('contact_form')) {
	contactSubmit();
}

if (document.getElementById('home')) {
	homeIframe.init();
}